import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {
    /*
      draw_Toggle_v1()
      button_Home()
      button_Back()
    */
    
    draw_Toggle_v1(selection, style_, callFunc) {
      let status = this[style_.variable]
      selection
      .append('g')
      .attr('id', `${style_.id}_group`)
      .attr('transform', `translate(${style_.x}, ${style_.y})`)
      .style('font-size', 10)
      .style('cursor', 'pointer')
      .call(callFunc, style_)
      
      d3.select(`#${style_.id}_group`)
      .append('rect')
      .attr('class', `${style_.id}_bg`)
      .attr('transform', `translate(20, 0)`)
      .attr('width', style_.width).attr('height', 18)
      .attr('fill', style_[status].bcolor)
      .attr('rx', '10')

      d3.select(`#${style_.id}_group`)
      .append('circle')
      .attr('class', `${style_.id}_slider`)
      .attr('transform', `translate(${style_[status].slide_x}, ${style_[status].slide_y})`)
      .attr("r", 6).attr('fill', style_.ccolor)

      d3.select(`#${style_.id}_group`)
      .append('text')
      .attr('class', `${style_.id}_text`)
      .attr('transform', `translate(${style_[status].text_x}, ${style_[status].text_y})`)
      .style('fill', style_[status].tcolor).style('alignment-baseline', 'middle')
      .text(style_[status].value)

      d3.select(`#${style_.id}_group`)
      .append('text')
      .attr('transform', `translate(65, 9)`)
      .style('font-size', 10)
      .style('alignment-baseline', 'middle')
      .style('fill', '#000')
      .text(style_.text)
    },

    toggle_Action_v1(status, style_) {
      d3.select(`.${style_.id}_bg`)
      .transition()
      .attr('fill', style_[status].bcolor)

      d3.select(`.${style_.id}_slider`)
      .transition()
      .attr('transform', `translate(${style_[status].slide_x}, ${style_[status].slide_y})`)

      d3.select(`.${style_.id}_text`)
      .transition()
      .attr('transform', `translate(${style_[status].text_x}, ${style_[status].text_y})`)
      .style('fill', style_[status].tcolor)
      .text(style_[status].value)
    },


    button_Home(selection, d_, callFunc) {
      let defs = this.svg.append('defs')
      let grayGrandient = defs.append('linearGradient')
      .attr('id', `grayGrandient`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      grayGrandient.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      grayGrandient.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      var btn_Home = selection
      .append('g')
      .attr('transform', `translate(${d_.x}, ${d_.y})scale(${d_.scale})`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Home.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Home.transition().duration(150).style("opacity", 1)
      })
      .call(callFunc, d_)

      btn_Home
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Home
      .append('path').attr('fill', 'url(#grayGrandient)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Home
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M29.9,19.4l-5.2-5.2l-5.2-5l-5.2,5l-5.2,5.2h3.4v9.2h5.1v-7.2h3.8v7.2h5.1v-9.2  C26.5,19.4,29.9,19.4,29.9,19.4z')
    },

    button_Home_v2(selection, d_, callFunc, exeFunc) {
      let defs = this.svg.append('defs')
      let grayGrandient = defs.append('linearGradient')
      .attr('id', `grayGrandient`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      grayGrandient.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      grayGrandient.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      var btn_Home = selection
      .append('g')
      .attr('transform', `translate(${d_.x}, ${d_.y})scale(${d_.scale})`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Home.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Home.transition().duration(150).style("opacity", 1)
      })
      .call(callFunc, exeFunc)

      btn_Home
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Home
      .append('path').attr('fill', 'url(#grayGrandient)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Home
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M29.9,19.4l-5.2-5.2l-5.2-5l-5.2,5l-5.2,5.2h3.4v9.2h5.1v-7.2h3.8v7.2h5.1v-9.2  C26.5,19.4,29.9,19.4,29.9,19.4z')
    },


    button_Back(selection, d_, callFunc) {
      let defs = this.svg.append('defs')
      let grayGrandient = defs.append('linearGradient')
      .attr('id', `grayGrandient`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      grayGrandient.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      grayGrandient.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      var btn_Back = selection
      .append('g')
      .attr('transform', `translate(${d_.x}, ${d_.y})scale(${d_.scale})`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Back.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Back.transition().duration(150).style("opacity", 1)
      })
      .call(callFunc, d_)

      btn_Back
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Back
      .append('path').attr('fill', 'url(#grayGrandient)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Back
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M25.2,27.3c0.4,0.2,0.7,0,0.7-0.4V11.5c0-0.5-0.3-0.7-0.7-0.4l-13.3,7.8c-0.4,0.2-0.4,0.6,0,0.9  L25.2,27.3z')
    },

    button_Back_v2(selection, d_, callFunc, exeFunc) {
      let defs = this.svg.append('defs')
      let grayGrandient = defs.append('linearGradient')
      .attr('id', `grayGrandient`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      grayGrandient.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      grayGrandient.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      var btn_Back = selection
      .append('g')
      .attr('transform', `translate(${d_.x}, ${d_.y})scale(${d_.scale})`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Back.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Back.transition().duration(150).style("opacity", 1)
      })
      .call(callFunc, exeFunc)

      btn_Back
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Back
      .append('path').attr('fill', 'url(#grayGrandient)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Back
      .append('path').attr('fill', '#5B6678')
      .attr('d', 'M25.2,27.3c0.4,0.2,0.7,0,0.7-0.4V11.5c0-0.5-0.3-0.7-0.7-0.4l-13.3,7.8c-0.4,0.2-0.4,0.6,0,0.9  L25.2,27.3z')
    },
  }
}
