import * as d3 from 'd3'

export default {
  methods: {

    draw_SVG() {
      let svgArea = this.svg
      .append('g')
      .attr('id', `svg_area_${this.localId}`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')

      if (this.extension === 'svg') {
        d3.xml(`${this.route}`).then(source => {
          let svg_ = source.documentElement
          
          svgArea.node().append(svg_)
  
          d3.select(svg_).selectAll('image')
          .attr('transform', `scale(${this.scale})`)
  
          svg_ = d3.select(svg_)
          .attr('x', this.x)
          .attr('y', this.y)
  
          return new Promise(resolve => {
            resolve(svg_)
          })
        })
      } else {
        svgArea
        .append('image')
        .attr('xlink:href', `${this.route}`)
        .attr('transform', `translate(${this.x}, ${this.y}) scale(${this.scale})`)
      }
    },
    
  }
}