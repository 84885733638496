import router from '@/router'
import axios from 'axios'
import { Service } from 'axios-middleware'

import __M from 'moment'
import __C from '../../primitives/_constant_'

export default class Api {

  constructor() {
    // this.cancelToken = axios.CancelToken
    // this.cancel

    this.service = new Service(axios)
    this.service.register({
      onResponse(response) {
        let token_ = response.headers['refresh-token']

        if(token_ && token_ != localStorage.getItem(__C.LOCAL_STORAGE_NAME.TOKEN)) {
          let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
          account.token = response.headers['refresh-token']
          
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.TOKEN, response.headers['refresh-token'])
          localStorage.setItem(__C.LOCAL_STORAGE_NAME.TIMEOUT, __M().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(response && response.data) {
          // 
        } else {
          response.data = {}
        }
        return response
      },
      onResponseError(err) {
        switch(err.response.status) {
          case 401: // ErrUnauthorized
            console.log(`[USER #API RESPONSE#] err.response.status: %s`, err.response.status)
            break
          case 428: // ErrExpired
            console.log(`[USER #API RESPONSE#] err.response.status: %s`, err.response.status)
            break
          case 511: // ErrNoTokenFound
            console.log(`[USER #API RESPONSE#] err.response.status: %s`, err.response.status)
            break
          case 501: // ErrAlgoInvalid
            console.log(`[USER #API RESPONSE#] err.response.status: %s`, err.response.status)
            break
          case 406: // ErrInvalidToken
            console.log(`[USER #API RESPONSE#] err.response.status: %s`, err.response.status)
            break
          default: // etc.
        }

        localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
        window.location.href = __C.CONFIG.FOR_LOCAL ? __C.HOST_NAME : __C.LANDING_PAGE_NAME
      }
    })
    this.token = ''
  }

  requestHeader(auth = true, options = {}) {
    let requestOptions = options
    if(!!auth) {
      // return authorization header with jwt token
      let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
      if (account && account.token) {
        requestOptions['Authorization'] = 'Bearer ' + account.token
      }
    }
  }

  tokenString() {
    let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
    if (account && account.token) return 'Bearer ' + account.token
    return ''
  }

  axios(tokenAuth=true, reqCancel=true) {
    if(tokenAuth) {
      axios.defaults.headers.common['Authorization'] = this.tokenString()
    } else {
      //
    }
    // if(reqCancel) {
    //   let _self = this
    //   axios.defaults.cancelToken = new axios.CancelToken(function executor(c) {
    //     // An executor function receives a cancel function as a parameter
    //     _self.cancel = c;
    //   })
    // } else {
    //   //
    // }
    return axios
  }
}