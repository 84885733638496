import Vue from 'vue'

import __C from '../../primitives/_constant_'
import LocalStorage from '../../services/local.storage/appservice.persistence'
import { ApplicationService } from '../../services'

const _db = new LocalStorage()
const _applicationService = new ApplicationService()

export default {
  namespaced: true,
  state: {
    homepageURL: '',
    navDataitems: [],
    navStatitems: [],
    navState: {},
    // page-load (not just loading) call function
    pagecallFunc: null,
    reportCategory: 'ALL',
    accessHistUserId: 'NULL',
  },
  getters: {
    categoryMenuPhase: (state) => {
      return state.navDataitems.map(p_ => ({ name: p_.phaseName, code: p_.phaseCode }))
    },
    navStateId: (state) => {
      return state.navState.id
    },
  },
  mutations: {
    setHomepageURL (state) { 
      let path_ = state.navStatitems.find(s_ => ['HM', 'M1'].includes(s_.parentCode))

      // Vue Instance-Global variable -----------
      Vue.prototype.$__home = path_ ? path_.url : '/service'
      // ----------------------------------------
      state.homepageURL = path_ 
    },
    setNavDataitems (state, items) { state.navDataitems = items },
    setNavStatitems (state, items) { state.navStatitems = items },
    setNavState (state, state__) { 
      // object: navState object
      // string: path
      if(typeof state__ == 'object') state.navState = state__ 
      else state.navState = state.navStatitems.find(item => item.url == state__)
    },
    setPagecallFunc (state, f) { state.pagecallFunc = f },
    setReportCategory (state, code) { state.reportCategory = code },
    setAccessHistUserId (state, userId) { state.accessHistUserId = userId },
  },
  actions: {
    initialize({ state, rootState, commit, dispatch }) {
      return new Promise(resolve => {
        dispatch('getNavigationData').then((navItems) => {
          let path_ = rootState.route.path == '/' ? state.homepageURL : rootState.route.path
          let navState_ = state.navStatitems.find(item => item.url == path_)
          commit('setNavState', navState_ || {})
          resolve(navItems)
        })
        // dispatch(
        //   `${__C.STORE_NAMESPACE.NOTICE}/getUnreadNoticeNum`,
        //   rootState[__C.STORE_NAMESPACE.ACCOUNT]['account'].id, 
        //   { root: true }
        // )
      })
    },
    getNavigationData({ rootState, commit }) {
      return new Promise(resolve => {
        _applicationService.getNavigationMenu(
          (data) => { 
            commit('setNavDataitems', data.route || [])
            commit('setNavStatitems', data.state || [])
            commit('setHomepageURL')
            resolve(data.route)
          },
          rootState[__C.STORE_NAMESPACE.ACCOUNT]['account'].userPermit
        )
      })
    },
    getBoardReportValues ({ commit }, id) {
      return _db.getBoardReportValues(id).then(res => {
        return new Promise(resolve => {
          commit('appsvc/setRecentDownloads', [ ...res.recent || [] ], { root: true })
          resolve(res)
        })
      })
    },
    updateBoardReportValues ({ commit }, data) {
      commit('appsvc/setRecentDownloads', [ ...data.values.recent || [] ], { root: true })
      return _db.updateBoardReportValues(data.id, data.values)
    },

    getFileBrowserValues ({ commit }, id) {
      return _db.getFileBrowserValues(id).then(res => {
        return new Promise(resolve => {
          commit('appsvc/setRecentDownloads', [ ...res.recent || [] ], { root: true })
          resolve(res)
        })
      })
    },
    updateFileBrowserValues ({ commit }, data) {
      commit('appsvc/setRecentDownloads', [ ...data.values.recent || [] ], { root: true })
      return _db.updateFileBrowserValues(data.id, data.values)
    },
  }
}
