import { AdminApi } from './api'

export default class SystemService {
    
  constructor() {
    this.adminApi = new AdminApi()
  }

  getMembers (permitCode, callback) {
    this.adminApi.getMembers(permitCode, res => {
      res.forEach(r => {
        r.regDate = r.regDate.substring(0, 10)
      })
      callback(res)
    })
  }
  getBPMembers (permitCode, callback) {
    this.adminApi.getBPMembers(permitCode, res => {
      res.forEach(r => {
        r.regDate = r.regDate.substring(0, 10)
      })
      callback(res)
    })
  }
  getMember (memberID, callback) {
    this.adminApi.getMember(memberID, res => {
      callback(res)
    })
  }
  putMember (member, callback) {
    this.adminApi.putMember(member, res => {
      callback(res)
    })
  }
  putAdminMember (member, callback) {
    this.adminApi.putAdminMember(member, res => {
      callback(res)
    })
  }
  updMember (member, callback) {
    this.adminApi.updMember(member, res => {
      callback(res)
    })
  }
  updAdminMember (member, callback) {
    this.adminApi.updAdminMember(member, res => {
      callback(res)
    })
  }
  delMember (sn, callback) {
    this.adminApi.delMember(sn, res => {
      callback(res)
    })
  }

  getUpdateItems (callback) {
    this.adminApi.getUpdateItems(res => {
      callback(res)
    })
  }

  putUpdateItems (upItems, callback) {
    this.adminApi.putUpdateItems(upItems, res => {
      callback(res)
    })
  }

  editUpdateItems (upItems, callback) {
    this.adminApi.editUpdateItems(upItems, res => {
      callback(res)
    })
  }

  delUpdateItems (upItems, callback) {
    this.adminApi.delUpdateItems(upItems, res => {
      callback(res)
    })
  }

  getWorkorders () {
    return this.adminApi.getWorkorders()
  }
  getWorkorder (idx) {
    return this.adminApi.getWorkorder(idx)
  }
  putWorkorder (params) {
    return this.adminApi.putWorkorder(params)
  }
  updWorkorder (params) {
    return this.adminApi.updWorkorder(params)
  }
  delWorkorder (idx) {
    return this.adminApi.delWorkorder(idx)
  }
}
