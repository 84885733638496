import * as d3 from 'd3'

export default {
  methods: {

    redraw_Overall(unit, idx) {
      
      let chartBase = this.colHeight-60
      d3.select( `#SYS_${idx}`).text('Overall Status').attr('fill', '#333')
      

      // SUMMARY Table -----------------------------------------------------------------------------------------
      let code =   ['A', 'B', 'C', 'AP', 'BP', 'CP']
      code.forEach(c=> {

      let TOT = unit[`${c}_TOT`]
      let PRO = unit[`${c}_PRO`]
      
      // Item Name
      d3.select(`#ITEM_${idx}_${c}_NAME`)
      .attr('opacity', TOT == 0 ? 0.2 : 1)

      // Total
      d3.select(`#ITEM_${idx}_${c}_TOT`).transition()
      .ease(d3.easeLinear).duration(300)
      .attr('opacity', TOT == 0 ? 0.2 : 1)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), unit[`${c}_TOT`])
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })
      d3.select(`#ITEM_${idx}_${c}_TOT`)
      .call(this.call_Summary, {unit: unit.UNIT, sSys: 'OV', item: c, cols: 'TOT'}) // ---> Event.js
         
      // Actual
      d3.select(`#ITEM_${idx}_${c}_ACT`).transition()
      .attr('opacity', TOT == 0 ? 0.2 : 1)
      .attr('x', PRO < 70 ? 45 + PRO + 2 : 45 + PRO - 2)
      .attr('text-anchor', PRO < 70 ? 'start' : 'end')
      .ease(d3.easeLinear).duration(300)
      .attr('opacity', TOT == 0 ? 0.2 : 1)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), unit[`${c}_ACT`])
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })
      d3.select(`#ITEM_${idx}_${c}_ACT`)
      .call(this.call_Summary, {unit: unit.UNIT, sSys: 'OV', item: c, cols: 'ACT'}) // ---> Event.js

      // Remain
      d3.select(`#ITEM_${idx}_${c}_REM`).transition()
      .attr('opacity', TOT == 0 ? 0.2 : 1)
      .text( unit[`${c}_REM`])
      .ease(d3.easeLinear).duration(300)
      .attr('opacity', TOT == 0 ? 0.2 : 1)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), unit[`${c}_REM`])
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })
      d3.select(`#ITEM_${idx}_${c}_REM`)
      .call(this.call_Summary, {unit: unit.UNIT, sSys: 'OV', item: c, cols: 'REM'}) // ---> Event.js

      // Progress Bar
      d3.select(`#ITEM_${idx}_${c}_PRO`).transition().duration(300)
      .attr('width', PRO)
    })
       


    // Axies -----------------------------------------------------------------------------------------
    let items =  ['A', 'B']
    items.forEach(item=> {

      let data = this.Rundown.filter(f=> f.UNIT == unit.UNIT && f.SUBSYS_NO == 'OV' && f.ITEM == item)
      d3.select(`#NoData_${idx}_${item}`).attr('opacity', 0)
      
      // Primary 
      let maxPrimary = this.getMaxBound_v2(unit[`${item}_TOT`])
      this.scalePrimary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([chartBase, 0])

      this.Grids.forEach((d,i)=> {
        d3.select(`#Primary_${idx}_${item}_${i}`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', 1)
        .text(maxPrimary * (i*2)/10)
      })

      // Secondary 
      let maxSecondary = this.getMaxBound_v2(d3.max(data, c => c.CLOSED_WK))
      this.scaleSecondary = d3.scaleLinear()
      .domain([0, maxSecondary])
      .range([chartBase, 0])
      
      this.Grids.forEach((d,i)=> {
        d3.select(`#Secondary_${idx}_${item}_${i}`).transition()
        .ease(d3.easeLinear).duration(300)
        .attr('opacity', 1)
        .text(maxSecondary * (i*2)/10)
      })

      // Bar Chart 
      data.forEach((d,i)=> {
        d3.select(`#Bar_${idx}_${item}_${i}`).transition().duration(300)
        .attr('y', this.scaleSecondary(d.CLOSED_WK))
        .attr('height', chartBase - this.scaleSecondary(d.CLOSED_WK))
        .attr('opacity', .5)
      })


      // Line Chart 
      let last_Date = null
      let last_Cum = null
      let lineFunc = d3.line()
      .x(d => this.scaleX(d.CDATE) + 5)
      .y(d => this.scalePrimary(d.REMAIN_CUM))

      d3.select(`#Line_${idx}_${item}`).transition().duration(300)
      .attr('d', lineFunc(data)).attr('opacity', 1)

      data.forEach((d,i)=> {
        d3.select(`#Line_${idx}_${item}_${i}`).transition().duration(300)
        .attr('cx',this.scaleX(d.CDATE) + 5)
        .attr('cy',this.scalePrimary(d.REMAIN_CUM))
        .attr('opacity', 1)
        last_Date = this.scaleX(d.CDATE)
        last_Cum = this.scalePrimary(d.REMAIN_CUM)
      })
      
      // Outstanding 
      d3.select(`#Remain_${idx}_${item}`).transition()
      .ease(d3.easeLinear).duration(300)
      .attr('opacity', 1)
      .attr('x', last_Date + 10)
      .attr('y', last_Cum + 3)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), unit[`${item}_REM`])
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })

    })








    },
  }
}