import * as d3 from 'd3'

export default {

  methods: {

    draw_Information() {
      
      let detail = this.detail
      if(!this.Queries.SQL3 || detail.visible !== 'Y') return

      // ---------------------------------------
      // Construction Stage ---> Global Function
      // ---------------------------------------
      let DetailBox = this.SkylineCharts
      .append('g')
      .attr('id', 'Detail_Group')
      .attr('transform', `translate(${detail.x}, ${detail.y})`)
      .attr('font-family', this.skyline.skyBox.font)
      .attr('opacity', detail.onOff == 'ON' ? 1 : 0)

      this.drawGroupBox(DetailBox, {
        x         : 0,
        y         : 0,
        width     : detail.width,
        height    : detail.height,
        label     : '',
        labelWidth: 0,
      })
      
      DetailBox
      .append('rect') // Fill Color
      .attr('x', 5).attr('y', -8)
      .attr('rx', 2).attr('ry', 2)
      .attr('width', detail.title.width).attr('height', 16)
      .attr('fill', '#F8FAFB').attr('fill-opacity', 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      DetailBox
      .append('text')
      .attr('id', `SKY_NO`)
      .attr('x', 5 + (detail.title.width/2)).attr('y', 3.7)
      .attr('font-size', 11).attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .text(this.rawData[0][this.skyline.refColumn])

      let y_ = 25

      detail.codes.forEach((d,i)=> {

        DetailBox
        .append('text')
        .attr('id',`detail_name_${i}`)
        .attr('x', 10).attr('y', y_)
        .attr('font-size', detail.tSize).attr('fill', '#000').attr('text-anchor', 'start')
        .text(detail.name[i].replace('-', ' '))

        this.detail.item.name.push(10 + this.getNodeElValue(`#detail_name_${i}`, 'width') + 5)
        if(detail.type[i] == 'TEXT') this.draw_detailText(DetailBox, detail.codes[i], this.detail.item.name[i], y_, i)
        if(detail.type[i] == 'TRIM') {
          this.detail.wrapSize =  this.detail.width - this.detail.item.name[i] - 5
          this.draw_detailText(DetailBox, detail.codes[i], this.detail.item.name[i], y_, i)
        }
        if(detail.type[i] == 'BAR') this.draw_detailBarChart(DetailBox, detail.codes[i], this.detail.item.name[i], y_, i)

        if(detail.type[i] == 'TRIM') y_ += 0
        y_ += detail.lineHeight
      })

    },
    

    draw_detailText(selection, code_, x_, y_, i) {
      selection
      .append('text')
      .attr('id', `TEXT_${i}`)
      .attr('x', x_).attr('y', y_)
      .attr('font-size', this.detail.tSize)
     . attr("dy", '0em')
      .attr('fill', this.detail.tColor[i]).attr('text-anchor', 'start')
      .text(this.rawData[0][code_])
      .call(this.trimText, `#TEXT_${i}`, this.detail.type[i]) // call trimText
    },
    
    trimText(text_, id_, type_) {
      if(type_ !== 'TRIM') return

      let getText = text_.text()
      for(var i=0; i < getText.length+1; i++){
   
        text_.text(getText.substr(0,i))
        if(this.getNodeElValue(id_, 'width') > this.detail.wrapSize - 15) {
          text_.text(`${getText.substr(0,i)}...`)
          return
        }
      }
    },


    draw_detailBarChart(selection, code_, name_x, y_, i) {

      selection // TOT
      .append('text')
      .attr('id', `DETAIL_TOT_${i}`)
      .attr('x', name_x).attr('y', y_)
      .attr('font-size', this.detail.tSize - .5)
      .attr('fill', this.detail.tColor[i]).attr('text-anchor', 'start')
      .text(this.rawData[0][`${code_}_TOT`])

      let val_X = this.getNodeElValue(`#DETAIL_TOT_${i}`, 'width')

      let pro_ = this.rawData[0][`${code_}_PRO`]
      let actX = (pro_/100) * 75

      selection // ACT Background
      .append('rect')
      .attr('id', `DETAIL_BAR_BG_${i}`)
      .attr('x', name_x + val_X + 2).attr('y', y_ - 9.5)
      .attr('width', 75).attr('height', 12)
      .attr('fill', '#E0E0DF').attr('fill-opacity', .3)

      selection // ACT Bar Chart
      .append('rect')
      .attr('id', `DETAIL_BAR_ACT_${i}`)
      .attr('x', name_x + val_X + 2).attr('y', y_ - 9.5)
      .attr('width', actX).attr('height', 12)
      .attr('fill', this.detail.bColor[i])

      selection // ACT Value
      .append('text')
      .attr('id', `DETAIL_ACT_VALUE_${i}`)
      .attr('x', pro_ > 70 ? name_x + val_X + actX : name_x + val_X + actX + 4)
      .attr('y', y_)
      .attr('font-size', this.detail.tSize - .5)
      .attr('fill', (pro_ > 70) ? '#fff': this.detail.tColor[i])
      .attr('text-anchor', pro_ > 70 ? 'end' : 'start')
      .text(this.rawData[0][`${code_}_ACT`])

      selection // REM Value
      .append('text')
      .attr('id', `DETAIL_REM_${i}`)
      .attr('x', name_x + val_X + 75 + 4).attr('y', y_)
      .attr('font-size', this.detail.tSize - .5)
      .attr('fill', this.detail.tColor[i]).attr('text-anchor', 'start')
      .text(this.rawData[0][`${code_}_REM`])
    },


    mouseOver_Detail(skyNo) {
      if(!this.Queries.SQL3 || this.detail.visible !== 'Y') return

      let d = this.rawData.find(f=> f[this.skyline.refColumn] == skyNo)

      // Group Name
      d3.select(`#SKY_NO`).transition().duration(200)
      .text(skyNo)
      
      this.detail.codes.forEach((c,i)=> {
        d3.select(`#detail_name_${i}`)
        .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : '#000')

        d3.select(`#TEXT_${i}`)
        .text(d[`${c}`])
        .call(this.trimText, `#TEXT_${i}`, this.detail.type[i]) // call - trimText

        // TOT
        if(this.detail.type[i] == 'BAR') {
          d3.select(`#DETAIL_TOT_${i}`)
          .text(d[`${c}_TOT`])
          .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : this.detail.tColor[i])
        }
      })

      this.detail.codes.forEach((c,i)=> {
        // Bar Chart
        if(this.detail.type[i] == 'BAR') {
          
          d3.select(`#DETAIL_TOT_${i}`).transition().duration(200)
          .text(d[`${c}_TOT`])

          let name_x = this.detail.item.name[i]
          let val_X = this.getNodeElValue(`#DETAIL_TOT_${i}`, 'width')
          let pro_ = d[`${c}_PRO`]
          let actX = (pro_/100) * 75

          // ACT Background
          d3.select(`#DETAIL_BAR_BG_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 2)
          .attr('fill-opacity', d[`${c}_TOT`] == 0 ? 0.05 : .3)

          // ACT Bar Chart
          d3.select(`#DETAIL_BAR_ACT_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 2)
          .attr('width', actX)

          // ACT Value
          d3.select(`#DETAIL_ACT_VALUE_${i}`).transition().duration(200)
          .attr('x', d[`${c}_PRO`] > 70 ? name_x + val_X + actX  :  name_x + val_X + actX + 4)
          .attr('text-anchor', d[`${c}_PRO`] > 70 ? 'end' : 'start')
          .attr('fill', ()=> {
            if(d[`${c}_TOT`] == 0) return '#EEEEEE' 
            else { 
              if(d[`${c}_PRO`] > 70) {
                return '#fff'
              } else {
                return this.detail.tColor[i]
              }
            }
          })
          .text(d[`${c}_ACT`])

          // REM Value
          d3.select(`#DETAIL_REM_${i}`).transition().duration(200)
          .attr('x', name_x + val_X + 75 + 4)
          .attr('fill', d[`${c}_TOT`] == 0 ? '#EEEEEE' : this.detail.tColor[i])
          .text(d[`${c}_REM`])
        }
      })
    },

  }
}
