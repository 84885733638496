import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {
    draw_SkylineCharts() {

      let self = this
      this.boxGroup = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes_fc')
      .attr('transform', `translate(${this.timeline.baseX}, 0)`)

      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this);
        });
      }

      this.timeline.week.forEach((w, i) => {

        let drawY = this.timeline.baseY - this.skyline.skyBox.height - (this.skyline.skyBox.gap/2)
        let currentSheet = this.sheet.prefix
        let dateFiltered = null

        // Not display boxes when the value of Total is 0
        if (this.sheet.zero == 'Y') dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)
        else dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)

        dateFiltered.forEach((d, i) => {
          
          let x1 = this.timeline.scale(d.sDate) + this.skyline.skyBox.gap
          let y1 = drawY
          let width_ = this.timeline.scale(d.cDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap

          let Boxes = this.boxGroup
          
          .append('g')
          .attr('id', `BOX_FC_${d[this.skyline.refColumn]}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            let factor = (self.skyline.scale - 1) / 2
            let scale_x = width_ * factor
            let scale_y = self.skyline.skyBox.height * factor
            
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-scale_x},${y1-scale_y}) scale(${self.skyline.scale})`)

            d3.select('#Detail_Group_FC').transition().duration(300)
            .style('opacity', 1)

            self.mouseOver_Detail(d[self.skyline.refColumn]) // ---> Detailed Information
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)

            d3.select('#Detail_Group_FC').transition().delay(500).duration(300)
            .style('opacity', self.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.skyline.refColumn]) })

          Boxes
          .append('rect') // Background
          .attr('x', 0).attr('y',0)
          .attr('rx', 0)
          .attr('ry', 0)
          .attr('width', width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', 'white')

          Boxes
          .append('rect') // Fill Color
          .attr('x', 0).attr('y',0)
          .attr('rx', 0)
          .attr('ry', 0)
          .attr('width', d.type == 'PRO' || d.type == 'REMAIN' ? width_ * (d[`${currentSheet}_PRO`]/100) : width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', d.bColor)
          .attr('fill-opacity', d.opacity)


          Boxes
          .append('rect') // Background
          .attr('x', 0).attr('y',0)
          .attr('rx', 0)
          .attr('ry', 0)
          .attr('width', width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', 'none')
          .attr('fill-opacity', d.opacity)
          .attr('stroke', d.CRITICAL_SUBSYS == 'Critical-Subsystem' ? '#f21616' : d.COMM_TYPE == 'Comm' ? '#333' : '#bcbcbc')
          .attr('stroke-width', d.CRITICAL_SUBSYS == 'Critical-Subsystem' ? 1.5 : 1).attr('stroke-opacity', d.CRITICAL_SUBSYS == 'Critical-Subsystem' ? .8 : .5)

          
          drawY = drawY - this.skyline.skyBox.height - (this.skyline.skyBox.gap)

          if(i == dateFiltered.length-1) {
            this.boxGroup
            .append('text')
            .attr('class', 'block_group_count_fc')
            .attr('transform', `translate(${x1 + (width_/2)}, ${drawY + this.skyline.skyBox.height - 5})`)
            .style('font-family', 'roboto')
            .style('font-size', 9)
            .style('fill', '#44A9DF')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .attr('visibility', 'visible')
            .text(dateFiltered.length)
            .style('cursor', 'pointer')
            .on('mouseover', function() {
              d3.select(this).transition().duration(300).style('opacity', .5).style('fill', 'red')
            })
            .on('mouseout', function(){
              d3.select(this).transition().duration(300).style('opacity', 1).style('fill', '#44A9DF')
            })
            .on('click', () => { 
              // console.log(
              //   'Week start Date:', moment(w.sDate).format('YYYY-MM-DD'),
              //   'Week end Date:', moment(w.eDate).format('YYYY-MM-DD')
              // )
              this.link_Subsystem({sDate: moment(w.sDate).format('YYYY-MM-DD'), eDate: moment(w.eDate).format('YYYY-MM-DD')})
            })
          }
        })
      })
    },


    draw_Behind() {

      let behind = this.SkylineCharts
      .append('g')
      .attr('class', 'behind_fc')
      .attr('transform', `translate(${this.timeline.baseX + 20}, ${this.timeline.baseY + 85})`)
      .attr('font-family', 'roboto')
      .attr('font-size', 9)

      behind
      .append('text')
      .attr('transform', `translate(${-10}, ${-12})`)
      .style('fill', '#EC407A')
      .attr('font-size', 11)
      .attr('alignment-baseline', 'middle')
      .text('List of behind subsystems')

      let x_ = 0, y_ = 0, cnt = 1, width_ = 75, height_ = 16
      this.timeline.week.forEach((w, i) => {

        let dateFiltered = this.rawData.filter(f => f.cDate == w.eDate && f.cDate < moment(this.timeline.cutoff))
        dateFiltered.filter(f => f.CSTD_ACTUAL == undefined).forEach((d, i) => {

          let behindBox = behind
          
          .append('g')
          .attr('id', `BHIND_BOX_FC_${d[this.skyline.refColumn]}`)
          .attr('transform', `translate(${x_},${y_})`)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            d3.select('#Detail_Group_FC').transition().duration(300)
            .style('opacity', 1)
            this.mouseOver_Detail(d[this.skyline.refColumn]) // ---> Detailed Information
          })
          .on('mouseout', () =>{
            d3.select('#Detail_Group_FC').transition().delay(500).duration(300)
            .style('opacity', this.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.skyline.refColumn]) })

          behindBox
          .append('rect') // Background
          .attr('x', 0).attr('y', 0)
          .attr('width', width_)
          .attr('height', height_)
          .attr('fill', 'white')
          .attr('stroke', '#bcbcbc')
          .attr('stroke-width', 1).attr('stroke-opacity', .5)

          behindBox
          .append('rect') 
          .attr('x', 0).attr('y', 0)
          .attr('width', d.type == 'PRO' || d.type == 'REMAIN' ? width_ * (d[`${this.sheet.prefix}_PRO`]/100) : width_)
          .attr('height', height_)
          .attr('fill', d.bColor)
          .attr('fill-opacity', d.opacity)
          .attr('stroke', '#bcbcbc')
          .attr('stroke-width', 1).attr('stroke-opacity', .1)

          behindBox
          .append('text')
          .attr('transform', `translate(8, 11)`)
          .style('fill', '#333').attr('font-size', 10)
          .text(d.SUBSYS_NO)
        
          x_ += 90
          if((cnt % 10) == 0) {
            y_  = y_ + 20
            x_ = 0
          }
          cnt += 1
        })

      })

    },

    
  }
}
