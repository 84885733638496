import { TokenCheckApi } from './api'

export default class TokenCheckService {
    
  constructor() {
    this.tokenCheckApi = new TokenCheckApi()
  }

  tokenCheck(token) {
    return this.tokenCheckApi.tokenCheck(token)
  }


}
