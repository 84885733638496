
import Url from './request.url'
import Api from './api'
export default class TokenCheckApi extends Api {
    
  constructor() {
    super()
  }

  tokenCheck(token) {
    return new Promise(resolve => {
      this.axios().get(`${Url.token.check}/${token}`).then(res => { resolve(res.data) })
    })
  }

  
}
