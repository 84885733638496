<template>
  <div class="j_service_svg_page">
    
    <div v-if="ExportPage">
      <button class="j_button type01 sky tabmodal" @click="excelExport">
        <i class="jcon_download_02"></i>
        EXCEL
      </button>    
    </div>

    <j-page-layout-builder-svg
      :mode="__C_.DASHBOARD.BUILDER_MODE_VIEWER"
      :chart-items="chartItems"
      :filters="filters.filters"
      @complete="onComplete"
      @request-action="onRequestedAction"
      @exportItems="exportItems"
    />

    <j-page-svg-information
      v-if="infoRequired"
      class="j_page_svg_information"
      for-print
      :chart-data="chartData"  
      :local-filters="filters.filters"
      :getSelectItems ="getSelectItems"
    />

    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
    />

  </div>
</template>

<script>

import __C from '@/primitives/_constant_'
import Loading from '@/mixins/loading.mixin'
import Url from '../../../src/services/api/request.url'
import { ApplicationService } from "@/services"
import JPageSvgInformation from '@/components/JPageSvgInformation'
import JServiceComponents from '@/components/JServiceComponentTargets'
import { mapState, mapGetters, mapMutations , mapActions } from 'vuex'
export default {
  name: "service-svg-page-single",
  components: {
    JPageSvgInformation,
    ...JServiceComponents
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        code: 'Modal',  // Page | Modal
        type: 'viewer', // viewer | export
        id: Number,
        no: String
      })
    },
  },
  mixins: [
    Loading
  ],
  data: () => ({
    // Service Instances -------------
    appService: null,

    chartItems: [],

    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,
    maBlock: null,
    tabStatus: [],
    getItems: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getModalExport', 'getSelectItems'
    ]),    
    __C_() { return __C },

    infoRequired() {
      if( this.getItems ) {
        this.filters.filters = { ...this.filters.filters, ...this.getItems,SEICODE_L1:this.getItems.area }
        return this.getItems.info == 'on' ? true : false
      }
      return this.filters.filters && this.filters.filters.infoRequired
    },
    chartData() {
      return []
    },
    ExportPage() {
      if (this.target.id == "372") return false
      if (this.getModalExport != "") {
        let data = JSON.parse(this.getModalExport)[0] 
        if (["Shop Drawing", "IFC Drawing", "Equipment"].includes(data.key)) return true
      }
      return false
    }
  },
  watch: {
    getSelectItems() {
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
    }
  //   target: {
  //     handler(val) {
  //       if(!val) return
  //       this.getPageSvg({
  //         idx: this.target.id,
  //         filters: this.filters.filters,
  //         iFilters: this.filters.iFilters
  //       })
  //     }
  //   }
  },
  created() {
    this.appService = new ApplicationService()
  },
  //mounted() {
  //   if(this.target.id) this.getPageSvg({
  //     idx: this.target.id,
  //     filters: this.filters.filters,
  //     iFilters: this.filters.iFilters
  //   })
  //},
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setPopupEqOpened',
      'setModalExport'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'GET_EXCEL',
      'GET_EXCEL_POST'
    ]),    

    onComplete(v) {
      if(this.chartItems.length > 1) return

      // v => { id: String, chart: Object }
      this.$emit('complete', {
        name: v.chart.Name,
        background: v.chart.CanvasFillColor,
        dimention: {
          width: v.chart.CanvasWidth,
          height: v.chart.CanvasHeight
        }
      })
    },
    onRequestedAction(request) {
      // Temporal Process, It will be replace with right 
      // process & object
      request = request.sender
      // ----------------------------------------------

      // let action___ = (
      //   request.sender.action && 
      //   request.sender.action.type == 'direct' ? 
      //   request.sender.action : 
      //   request.parent.action
      // )

      if(!request.action) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      if(request.action.target != __C.REQUEST_ACTION.TARGET_URL && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      if(request.action.target == __C.REQUEST_ACTION.TARGET_URL && !request.action.path) {
        console.log(`[USER: undefined 'URL Path'] URL Path is not defined.`)
        return
      }
      if(request.action.target == __C.REQUEST_ACTION.TARGET_URL) {
        window.open(request.action.path)
        return
      }
      // For the Pop-Up process
      if(request.action.target != __C.REQUEST_ACTION.TARGET_POPUP && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      if([
        __C.REQUEST_ACTION.TARGET_POPUP,
        __C.REQUEST_ACTION.TARGET_ROOM_POPUP
      ].includes(request.action.target)) {
        this.setPopupEqOpened({
          open: true,
          queryid: request.action.id,
          filters: request.filters
        })
        return
      }


      this.maOpened = true
      this.maComponent = request.action.component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.maFilters = {
        filters: { ...this.filters.filters, ...request.filters },
        iFilters: { ...request.iFilters }
      }

      if(this.maFilters && this.maFilters.filters && Object.keys(this.maFilters.filters).includes('SYS')) {
        this.setCommentProps({
          refCode: this.maFilters.filters.SYS
        })
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.maComponent} ${JSON.stringify(this.maTarget)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.maFilters.filters)}`)
      console.log(`[USER: #DEBUG] Filters [2]: ${JSON.stringify(this.maFilters.iFilters)}`)
    },
    async excelExport() {
      let data = JSON.parse(this.getModalExport)[0]
      let target = this.maBlock != null ? JSON.parse(this.maBlock) : ''
      let keySet = []
      let headers = data.headers.map(d => d.colname)
      data.items.forEach(d => {
        if (!d.hasOwnProperty('DISC')) {
          d.DISC = ""
        }
        let test = Object.keys(d)
        if (keySet.length < test.length) {
          keySet = [...test]
        }
      })
      keySet.forEach(d => {
        data.items.forEach(index => {
           if (!index.hasOwnProperty(d)) {
             index[d] = ""
           }
        })
      })
      headers.forEach(d => {
        data.items.forEach(index => {
           if (!index.hasOwnProperty(d)) {
             index[d] = ""
           }
        })
      })

      if (data.items.length < 1000) {
        let params = new URLSearchParams()
        params.append('items', JSON.stringify(data.items)) 
        params.append('headers',JSON.stringify(data.headers))
        params.append('level1', JSON.stringify(data.level1))
        params.append('datasource', data.datasource)
        params.append('key', data.key)
        params.append('token', this.account.token)


        const send_Data = {
          url_ : `${Url.fetch.excel.modal}?${params.toString()}`,
          title : `${data.key}`
        }

        await this.GET_EXCEL(send_Data)        
      } else {
        let params = new URLSearchParams()
        params.append('token', this.account.token)

        const send_Data = {
          url_ : `${Url.fetch.excel.modalPost}?${params.toString()}`,
          title : `${data.key}`,
          data  : data
        }

        await this.GET_EXCEL_POST(send_Data)  
      }
    },    
    exportItems(items) {
      this.setModalExport(JSON.stringify(items))
    },
    getPageSvg(request) {
      let params_ = new URLSearchParams()
      params_.append('idx', request.idx)
      params_.append('action', '')
      params_.append('fValues', request.filters ? JSON.stringify(request.filters) : '')
      params_.append('iFilters', request.iFilters ? JSON.stringify(request.iFilters) : '')
      params_.append('reqType', this.target.type || 'viewer')
      
      console.log(`[USER: #DEBUG] idx     [0]: ${request.idx}`)
      console.log(`[USER: #DEBUG] fValues [1]: ${request.filters ? JSON.stringify(request.filters) : ''}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${request.iFilters ? JSON.stringify(request.iFilters) : ''}`)
      console.log(`[USER: #DEBUG] reqType [3]: ${this.target.type || 'viewer'}`)
      console.log(`[USER: #DEBUG] getselectitem : ${this.getSelectItems}`)
      
      if(this.getSelectItems) this.getItems = this.getSelectItems

      this.maBlock = request.filters ? JSON.stringify(request.filters) : ''
      return new Promise((resolve) => {
        this.appService.getPageSvg(params_, (res) => {
          this.chartItems = [res.chartItem]
          resolve(res.chartItem)
        })
      })
    },
    run() {
      return this.getPageSvg({
        idx: this.target.id,
        filters: this.filters.filters,
        iFilters: this.filters.iFilters
      })
    }
  }
}


</script>
