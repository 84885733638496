import * as d3 from 'd3'

export default {
  methods: {
    draw_Viewer() {
      let viewer = this.sCanvasArea
      .append('g')
      .attr('id', 'filter_viewer')
      .attr('transform', `translate(0, ${this.attr.tSize})`)

      if (this.FilterValues) {
        if (Object.keys(this.FilterValues).length > 0) {
          this.filter.col.filter(f => Object.keys(this.FilterValues).includes(f)).forEach((d, i) => {
            if (this.attr.name == 'Y') {
              console.log(this.getNodeElValue('#filter_viewer', 'width'))
              this.draw_FilterText(viewer, `${this.filter.text[i]}: `, this.getNodeElValue('#filter_viewer', 'width'))
            }

            let value = this.FilterValues[d]
            let text = ''

            if (Array.isArray(value)) {
              if (value.length > 1) {
                text = `${value[0]} (+${value.length - 1})`
              } else {
                text = value[0]
              }
            } else {
              text = value
            }

            this.draw_FilterText(viewer, `${text}`, this.getNodeElValue('#filter_viewer', 'width'))

            if (i < Object.keys(this.FilterValues).length - 1) this.draw_FilterText(viewer, ' > ', this.getNodeElValue('#filter_viewer', 'width'))
          })
        }
      }
    },

    draw_FilterText(svg, t, x) {
      svg
      .append('text')
      .attr('x', x)
      .attr('font-size', this.attr.tSize)
      .attr('fill', this.attr.tColor)
      .attr('xml:space', 'preserve')
      .text(t)
    },
    
  }
}