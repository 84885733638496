export default {

  methods: {

    count_Package(data_){
      
      this.package.status.forEach((d,i)=> {
        if (d == 'ALL') this.package.values[d] = data_.length
        else this.package.values[d] = data_.filter(f=> f[this.package.refColumn] == d).length
      })
    },

    
    count_Sheet(data_){

      let prefix = this.sheet.prefix
      
      this.sheet.styles.forEach((s,i)=> {
        switch(s.type) {
          case 'LAYER':
            this.sheet.values[i] = data_.filter(f=> f[s.cstd] == s.status).length
          break
          default:
            if (s.status == 'TOTAL') this.sheet.values[0] = data_.length
            else this.sheet.values[i] = data_.filter(f=> f[`${prefix}_STATUS`] == s.status).length
          break
        }
      })
      // console.log(this.sheet)
    },


    count_Punch(data_) {

      let prefix = this.punch.prefix

      this.punch.styles.forEach( (d,i)=> {
        let num = d.status.replace(/\s/g, '').split('-')[1]

        switch(d.status) {
          case 'NONE':
            d.filtered = data_
          break

          case 'CREATE':
            d.filtered = data_.filter(f=> f[`${prefix}_TOT`] > 0)
          break

          case 'NOTSTARTED':
            d.filtered = data_.filter(f=> f[`${prefix}_TOT`] > 0 && f[`${prefix}_ACT`] == 0)
          break

          case 'OPENED':
            d.filtered = data_.filter(f=> f[`${prefix}_TOT`] > 0 && f[`${prefix}_REM`] > 0)
          break

          case 'REM-10':
            d.filtered = data_.filter(f=> f[`${prefix}_TOT`] > 0 && f[`${prefix}_REM`] < num)
          break

          case 'CLEARED':
            d.filtered = data_.filter(f=> f[`${prefix}_TOT`] > 0 && f[`${prefix}_REM`] == 0)
          break
        }
        this.punch.values[d.status] = d.filtered.length
      })
      // console.log(this.punch.values)
    },




  }
}