import __C from '../primitives/_constant_'
import { AccountApi } from './api'

export default class AccountService {
    
  constructor() {
    this.accountApi = new AccountApi()
  }

  signin(creds, callback) {
    this.accountApi.signin(creds, account => {
      // login success if there's a jwt token in the response
      if(account.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
        if(account.approval == "WAITING") account.error = true
      } else {
        account.error = true

        // const error = (data && data.message) || response.statusText;
        // callback(account)
      }
      // if(res.status === 200) {
      //   if(!!res.data.token) {
      //     localStorage.setItem('user', JSON.stringify(user))
      //   }
      // }
      callback(account)
    })
  }

  signout(callback) {
    // remove user from local storage to sign user out
    localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
    callback()
  }
}
