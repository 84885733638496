import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      this.legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend_fc')
      .attr('transform', `translate(${this.legends.x}, ${this.legends.y})`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Legends`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 53)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Comm. & Non-Comm. & Critical subsystem`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 95)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Subsystem Status`)

      this.legendGroup
      .append('text')
      .attr('transform', `translate(10, 137)`)
      .style('font-size', 10.5).attr('font-weight', 400).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Punch ${this.punch.prefix.substring(0,1)}`)

      this.legend_Comm() // ---> below
      this.legend_Sheet() // ---> below
      this.legend_Punch() // ---> below
      this.legend_LinkLine() // ---> below
    },

    legend_Comm() {
      let x_ = 0
      
      this.typeGroup = this.legendGroup
      .append('g')
      .attr('id', `Comm_Group_fc`)
      .attr('transform', `translate(18, 70)`)

      this.commType.forEach((s, i) => {
        
        let legendItem = this.typeGroup
        .append('g')
        .attr('id', `CommType_fc_${s.text}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        // .attr('opacity', this.legends.commStatus == s.text ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#CommType_fc_${s.text}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#CommType_fc_${s.text}`).attr('opacity', this.legends.commStatus == s.text ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#CommType_fc_${this.legends.commStatus}`).attr('opacity', 0.8)
          d3.select(`#Comm_text_fc_${this.legends.commStatus}`).attr('fill', '#000')

          this.legends.commStatus = s.text
          d3.select(`#CommType_fc_${s.text}`).style('opacity', 1)
          d3.select(`#Comm_text_fc_${s.text}`).attr('fill', '#EC407A')
          
          this.filtering_Search('COMM') // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('rect')
        .attr('transform', `translate(-5.5, -5.5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', s.sColor)
        .attr('stroke-width', 1).attr('stroke-opacity', .5)
        .attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `Comm_text_fc_${s.text}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 9)
        .attr('fill', s.text == this.legends.commStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(s.text == 'None' ? s.text : `${s.text} (${s.values})`)

        x_ += this.getNodeElValue(`#CommType_fc_${s.text}`, 'width') + 12
      })
    },


    legend_Sheet() {
      let x_ = 0

      this.sheetGroup = this.legendGroup
      .append('g')
      .attr('id', `Sheet_Group_fc`)
      .attr('transform', `translate(18.5, 112.5)`)

      this.sheet.styles.forEach((s, i) => {
        
        let legendItem = this.sheetGroup
        .append('g')
        .attr('id', `Sheet_fc_${s.status}`)
        .attr('transform', `translate(${x_}, 0)`)
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Sheet_fc_${s.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#Sheet_fc_${s.status}`).attr('opacity', this.legends.sheetStatus == s.status ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#Sheet_fc_${this.legends.sheetStatus}`).attr('opacity', 1)
          d3.select(`#Sheet_text_fc_${this.legends.sheetStatus}`).attr('fill', '#000')

          this.legends.sheetStatus = s.status
          this.legends.layerStatus = s.cstd
          d3.select(`#Sheet_fc_${s.status}`).style('opacity', 1)
          d3.select(`#Sheet_text_fc_${s.status}`).attr('fill', '#EC407A')
          
          this.filtering_Search('ITR') // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('rect')
        .attr('id', `Sheet_box_fc_${s.status}`)
        .attr('transform', `translate(-5, -5)`)
        .attr('rx', 2).attr('ry', 2)
        .attr('width', 10).style('height', 10)
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', 1).attr('stroke-opacity', .5)
        .attr('fill', s.color)

        legendItem
        .append('text')
        .attr('id', `Sheet_text_fc_${s.status}`)
        .attr('transform', `translate(11, 1)`)
        .attr('font-size', 9)
        .attr('fill', s.status == this.legends.sheetStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`${s.text} (${this.sheet.values[i]})`)

        x_ += this.getNodeElValue(`#Sheet_fc_${s.status}`, 'width') + 12
      })
      
    },

    

    legend_Punch() {
      let prefix = this.punch.prefix
      let x_ = 0

      this.punch.styles.forEach((s, i) => {
        
        let legendItem = this.legendGroup
        .append('g')
        .attr('id', `fc_${prefix}_${s.status}`)
        .attr('transform', `translate(${x_+ 18}, 152)`)
        .style('font-family', 'roboto')
        .style('opacity', this.legends.punchStatus == s.status ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#fc_${prefix}_${s.status}`).style('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#fc_${prefix}_${s.status}`).style('opacity', this.legends.punchStatus == s.status ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`#fc_${prefix}_text_${this.legends.punchStatus}`).attr('fill', '#000')

          this.legends.punchStatus = s.status
          d3.select(`#fc_${prefix}_${this.legends.punchStatus}`).style('opacity', 1)
          d3.select(`#fc_${prefix}_text_${this.legends.punchStatus}`).attr('fill', '#EC407A')

          this.filtering_Search('PUNCH') // ---> Event.js
          this.filterStatus = 'ON'
        })
        
        legendItem
        .append('circle')
        .attr('id', `fc_${prefix}_box_${s.status}`)
        .attr('transform', `translate(0,-.5)`)
        .attr('r', 4)
        .attr('stroke', '#757575')
        .attr('stroke-width', .5)
        .attr('fill', '#fff')

        legendItem
        .append('text')
        .attr('id', `fc_${prefix}_text_${s.status}`)
        .attr('transform', `translate(7, 1)`)
        .style('font-size', 9)
        .attr('fill', s.status == this.legends.punchStatus ? '#EC407A' : '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(s.status == 'NONE' ? `${s.text}` : `${s.text} (${this.punch.values[s.status]})`)

        x_ += this.getNodeElValue(`#fc_${prefix}_${s.status}`, 'width') + 12
      })
    },


    legend_LinkLine(){

      let s1 = 19
      let linkLine = this.legendGroup
      
      .append('g')
      .attr('id', `fc_Punch_Line`)
      .attr('visibility', 'hidden')

      linkLine
      .append('line')
      .attr('id', `fc_Punch_S`)
      .attr('x1', s1).attr('y1', 76.5)
      .attr('x2', s1).attr('y2', 82.5)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)

      linkLine
      .append('line')
      .attr('id', `fc_Punch_M`)
      .attr('x1', s1).attr('y1', 82.5)
      .attr('x2', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y2', 82.5)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)

      linkLine
      .append('line')
      .attr('id', `fc_Punch_E`)
      .attr('x1', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y1', 82.5)
      .attr('x2', this.getNodeElValue(`#Sheet_TOTAL`, 'width')).attr('y2', 106)
      .attr('stroke', '#F7BACF').attr('stroke-width', .5)
    },
  }
}